import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getCategoryList,
  getBrandList,
  getCatList_SelectBox,
  getSubCategoryListByCategory,
  getBrandInventory,
  getBrandByCategory,
  getBrandByCategoryName,
  addImg_category,
} from "./inventoryUrls";

const cat_list = (qry) => {
  return useQuery(["categoryList", qry], () => getCategoryList(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const cat_List_SelectBox = () => {
  return useQuery("catListSelect", getCatList_SelectBox, {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const addImage_category = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addImg_category(data), {
    onSuccess: async (newProduct) => {
      queryClient.invalidateQueries("categoryList");;
      return newProduct;
    },
    onSettled: () => {
      queryClient.invalidateQueries("categoryList");
    },
    onError: (err, _, context) => {
    },
  });
};

//added currentPageNo argument for breaking changes
const subcat_list_by_category = (data, catId) => {

  return useQuery(
    ["subcategoryListByCategory", [catId, data]],
    () => getSubCategoryListByCategory(catId, data),
    {
      staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      poll: 500,
      enabled: Boolean(catId),
    }
  );
};

const brand_list = (qry) => {
  return useQuery(["brandList", qry], () => getBrandList(qry), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};
const brand_listInventry = () => {
  return useQuery(["brandList"], () => getBrandInventory(), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const brand_by_category = (id) => {
  return useQuery(["brandByCategoryList", id], () => getBrandByCategory(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
    enabled: Boolean(id),
  });
};

const brand_by_category_name = (id) => {
  return useQuery(["brandByCategoryNameList", id], () => getBrandByCategoryName(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
    enabled: Boolean(id),
  });
};

const useList = {
  cat_list,
  addImage_category,
  brand_list,
  cat_List_SelectBox,
  subcat_list_by_category,
  brand_listInventry,
  brand_by_category,
  brand_by_category_name,
};

export default useList;
