import { Box, IconButton, Grid, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import Sub from "../../common/Subheader/Sub";
import useAddCategory from "../../data/inventory/useAddCategory";
import useCategoryData from "../../data/inventory/useCategoryData";
import {
  FORMONE_BUTTON_MANAGEMENT,
  FORMONE_EDIT_MANAGEMENT,
  FORM_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import InventoryManagmentStyle from "./InventoryManagmentStyle";
import { ToastContainer, toast } from "react-toastify";
import { stubFalse } from "lodash";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import { AiOutlineClose } from "react-icons/ai";
import salesStyle from "../SalesPersonManagement/salesStyle";
import info_circle from "../../images/info-circle.svg"
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  fontFamily: "Montserrat",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffffff",
  boxShadow: 24,
  borderRadius: "10px",
};

const SpecificationKeys = ({
  openKey,
  handleKeys,
  values,
  setValues,
  handleInputChange,
  catId,
  errors,
  params,
}) => {
  const [open, setOpen] = useState(false);
  const [editopen, setEditopen] = useState(false)
  const [keysError, setKeysError] = useState("");
  const [oldSpecification, setOldSpecification] = useState([]);
  const [previewSpecification, setPreviewSpecification] = useState([]);
  const [editTag, setEditTag] = useState(null);
  const [editText, setEditText] = useState("");
  const categoryStyle = InventoryManagmentStyle();
  const handleOpen = () => {
    setOpen(!open);
    setEditopen(stubFalse)
  };

  const handle_edit_open = () => {
    // if (oldSpecification?.length == values?.edit_specification) {
    setEditopen(!editopen)
    setOpen(false)
    // }
  }

  const orderStyle = OrderManagmentStyle();
  const salesStyles = salesStyle();

  const { data: SpecificationById } = params.name
    ? useCategoryData.useSubcategoryList(catId)
    : useCategoryData.useCategoryList(catId);
  const {
    mutateAsync: addCategory,
    data: addCategoryData,
    isSuccess: addCategorySuccess,
  } = useAddCategory.add_cat(params.id);
  const {
    mutateAsync: editCategory,
    data: editCategoryData,
    isSuccess: editCategorySuccess,
  } = useAddCategory.edit_cat(params.name);
  useEffect(() => {
    if (SpecificationById?.specification_fields == null) {
      setOldSpecification([])
      setPreviewSpecification([])
    } else {
      setOldSpecification(
        SpecificationById &&
        SpecificationById.specification_fields &&
        SpecificationById.specification_fields.SpecificationFields?.split(",")?.filter(item => item !== "")
      );
      setPreviewSpecification(
        SpecificationById &&
        SpecificationById.specification_fields &&
        SpecificationById.specification_fields.SpecificationFields?.split(",")?.filter(item => item !== "")
      );
      // oldSpecification =
      //   SpecificationById &&
      //   SpecificationById.specification_fields
      setValues({
        ...values,
        specification: "",
      })
    }

  }, [SpecificationById]);

  // console.log("asdfsadfsdfsdfasdf", SpecificationById.specification_fields, oldSpecification)

  const handleDelete = (tagToDelete) => {
    setOldSpecification((prevTags) => prevTags?.filter((tag) => tag !== tagToDelete));
    setPreviewSpecification((prevTags) => prevTags?.filter((tag) => tag !== tagToDelete));
  };

  const handleTagClick = (tag) => {
    setEditTag(tag);
    setEditText(tag);
  };

  const handleEditSave = (e) => {
    if (e.key === "Enter") {
      setOldSpecification((prevTags) => {
        return prevTags.map((tag) =>
          tag === editTag ? editText : tag
        );
      });
      setPreviewSpecification((prevTags) => {
        return prevTags.map((tag) =>
          tag === editTag ? editText : tag
        );
      });
      setEditTag(null);
    }
  };

  const submitData = async (e) => {
    // console.log(values.specification);
    // return false;
    e.preventDefault();
    let err = false;
    // if (!values.specification) {
    //   setKeysError("Specification is required");
    //   err = true;
    // }
    // if (!err) {
    if (catId) {
      let updateddOldSpecification = oldSpecification?.push(values?.specification);
      // if (oldSpecification && oldSpecification.includes('null')) {
      //   updateddOldSpecification = oldSpecification.replace(/null,/g, '');
      // }
      await editCategory({
        id: catId,
        name: SpecificationById && SpecificationById.name,
        usp: SpecificationById && SpecificationById.usp,
        other_charges: SpecificationById && SpecificationById.other_charges,
        type: SpecificationById && SpecificationById.type,
        parent_id:
          params.id !== undefined && params.id !== null
            ? parseInt(params.id)
            : "",
        // specification_fields: {
        //   SpecificationFields: updateddOldSpecification + "," + values.specification,
        // },
        specification_fields: {
          SpecificationFields: oldSpecification?.length != 0 && oldSpecification?.join(','),
        },
      }).then((res) => {
        if (res.status === 200) {
          toast.success('Specification keys added', {
            hideProgressBar: true,
            autoClose: 2200,
          });
        }
      })
        .catch((err) => {
          if (err && err.response && err.response.status !== 403) {
            toast.error("Something went wrong", {
              hideProgressBar: true,
              autoClose: 2200,
            });
          }
        });
    } else {
      await addCategory({
        name: SpecificationById && SpecificationById.name,
        usp: SpecificationById && SpecificationById.usp,
        other_charges: SpecificationById && SpecificationById.other_charges,
        type: SpecificationById && SpecificationById.type,
        parent_id:
          params.id !== undefined && params.id !== null
            ? parseInt(params.id)
            : "",
        // specification_fields: {
        //   SpecificationFields: values.specification,
        // },
      });
    }
    handleKeys();
    // }
  };

  return (
    <div>
      <Modal open={openKey}>
        <Box className={categoryStyle.specifickeyContainer}>
          <Box className={orderStyle.assignlinkbox}>
            <Box></Box>
            <Typography className={salesStyles.assignTitle}>
              Specification Keys
            </Typography>
            <Box className={orderStyle.assignlinkclose}>
              <IconButton
                onClick={() => {
                  handleKeys()
                }}
              >
                <AiOutlineClose />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ width: "100%", padding: "10px" }}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography className={categoryStyle.categoryLabel} style={{ color: "#444445", fontWeight: "lighter" }}>
                  Add Specification Keys
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} style={{ padding: "10px 20px" }}>
                <Controls.TextArea
                  name="specification"
                  className={categoryStyle.specification_formtTextArea1}
                  value={values.specification}
                  onChange={handleInputChange}
                  placeholder={''}
                  error={keysError}
                  maxLength="250"
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%", padding: "0px" }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <label className={categoryStyle.categoryLabel} style={{ fontSize: "16px" }}>
                  Specification Keys Preview
                </label>
              </Grid>
              <Grid item xs={12} sm={12} style={{ padding: "10px 20px", position: "relative" }}>
                <div className={categoryStyle.specification_preview} style={{ padding: (!oldSpecification || oldSpecification?.length == 0) ? "12px" : "7px 12px" }}>
                  {(!oldSpecification || oldSpecification?.length == 0) && (
                    <span style={{ fontSize: "12px" }}>
                      <img
                        src={info_circle}
                        alt="Info Circle"
                        style={{ marginRight: "7px", marginBottom: "3px" }}
                      />
                      No Keys available
                    </span>
                  )}
                  <Box display="flex" gap={4} flexWrap="wrap">
                    {previewSpecification?.map((tag, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        padding="0 5px 0 10px"
                        height="30px"
                        width={"auto"}
                        bgcolor={editTag === tag ? "#ffffff" : "#CBCBCB"}
                        border={editTag === tag ? "" : "1px solid #CBCBCB"}
                        borderRadius="4px"
                        marginRight="10px"
                        marginBottom="5px"
                        marginTop={"5px"}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleTagClick(tag)}
                      >
                        {editTag === tag ? (
                          <input
                            type="text"
                            style={{
                              height: "30px", width: "100%", padding: "6px", border: "1px solid #FEDD17", borderRadius: "4px", outline: "none", boxShadow: "none"
                            }}
                            value={editText}
                            onChange={(e) => setEditText(e.target.value)}
                            onKeyDown={handleEditSave}
                            autoFocus
                          />
                        ) : (
                          <>
                            <Typography variant="body2" color="#444445" sx={{ marginRight: 1 }} style={{ fontSize: "13px" }}>
                              {tag}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() => handleDelete(tag)}
                              sx={{ color: "#444445" }}
                            >
                              <CloseIcon style={{ fontSize: "14px" }} />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>
                </div>
                <div style={{ marginTop: "5px" }}>
                  {(!oldSpecification || oldSpecification?.length == 0) ? (
                    <></>
                  ) : (
                    <span style={{ fontSize: "12px" }}>
                      <img
                        src={info_circle}
                        alt="Info Circle"
                        style={{ marginRight: "7px", marginBottom: "3px" }}
                      />
                      Click on tag to edit
                    </span>
                  )}
                </div>
              </Grid>


              <Grid xs={12} sm={6}>
                <Box
                  sx={{
                    display: { xs: "inline", sm: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButs
                    ibtname={FORMONE_BUTTON_MANAGEMENT}
                    className={categoryStyle.cancelButton}
                    onClick={handleKeys}
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={6}>
                <Box
                  sx={{
                    display: { xs: "inline", sm: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButs
                    ibtname={'Save'}
                    className={categoryStyle.formButtonAdd}
                    onClick={submitData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div >
  );
};

export default SpecificationKeys;
