import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Modal, IconButton, Grid, Box, Typography, CircularProgress, TextField,
    InputAdornment,
    Card,
    CardContent
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { AiOutlineClose } from "react-icons/ai";
import OrderManagmentStyle from '../Assign_Office_Advisor/OrderManagmentStyle';
import salesStyle from '../SalesPersonManagement/salesStyle';
import makeStyles from "../../sharedFeatures/sharedClasses";
import Controls from "../../common/Controls/Controls";
import IconButs from "../../common/Controls/IconButs";
import useAddProduct from "../../data/inventory/useAddProduct";
import {
    FORMONE_BUTTON_MANAGEMENT,
    FORM_BUTTON_MANAGEMENT,
} from "../../utilitis/header";
import Checkbox from '@mui/material/Checkbox';
import categoryBrandStyles from "./InventoryManagmentStyle";
import useList from "../../data/inventory/useList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FindLink } from "../../sharedFeatures/FindLink";
import { BsSearch } from "react-icons/bs";
import InventoryImage from "./inventoryImage";
import sample_img from '../../images/sample_add_ons.png'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { yellow } from "@material-ui/core/colors";
import closeSquare from '../../images/close_square.svg'
import productQuery from "../../data/inventory/useProductList";
import { debounce } from 'lodash';
import Search_Icon from '../../images/search_icon_add_ons.svg'

const AddInventoryProduct = (props) => {
    const orderStyle = OrderManagmentStyle();
    const salesStyles = salesStyle();

    const { open, setOpen, title } = props.AddProductModalProps

    const customStyle = makeStyles();
    const { mutate: addProductImage } = useAddProduct.addImage();

    const categoryStyle = categoryBrandStyles();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [specificationFileds, setSpecificationFields] = useState([]);
    const [specificationFiledsSub, setSpecificationFieldsSSub] = useState([]);

    const [imageOpen, setImageOPen] = useState(false);
    const [brand_id, setBrandID] = useState("");
    const [series, setSeries] = useState("");
    const [item_code, setItemCode] = useState("");
    const [model, setModel] = useState("");
    const [mrp, setMrp] = useState("");
    const [rcp, setRcp] = useState("");
    const [dealer_landing_price, setDealerLandingPrice] = useState("");
    const [customer_landing_price, setCustomerLandingPrice] = useState("");
    const [gst_perc, setGstPerc] = useState("");
    const [description, setDescription] = useState("");
    const [features, setFeatures] = useState("");
    const [service_items, setServiceItems] = useState("");
    const [timeForService, setTimeForSErvices] = useState("");
    const [dealerExchangePrice, setdealerExchangePrice] = useState("");
    const [customerExchangePrice, setcustomerExchangePrice] = useState("");
    const [warranty, setWarranty] = useState({});
    const [vehicle_category, setVehicleCategory] = useState("");
    const [vehicle_segment, setVehicleSegment] = useState(1);
    const [hsn_code, setHscCode] = useState("");
    const [advantages, setAdvantages] = useState("");
    const [margin, setMargin] = useState(null);
    const [catType, setCatType] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [subCategoryError, setSubCategoryError] = useState("");
    const [seriesErr, setSeriesErr] = useState("");

    const [brandError, setBrandError] = useState("");
    const [itemCodeErr, setItemCodeErr] = useState("");
    const [hsnCodeErr, setHsnCodeErr] = useState("");
    const [mrpError, setMrpError] = useState("");
    const [dealerPriceError, setDealerPriceError] = useState("");
    const [timeForServiceErr, setTimeForServiceErr] = useState("");
    const [vcatErr, setVcatErr] = useState("");
    const [vsecErr, setVsectErr] = useState("");
    const [gstErr, setgstErr] = useState("");

    const [customerPriceError, setCustomerPriceError] = useState("");
    const [rcpError, setRcpError] = useState("");
    const [hsnError, setHsnError] = useState("");
    const [specsError, setSpecError] = useState({});
    const [warrentError, setWarrentError] = useState({});
    const [imageData, setImageData] = useState([]);
    const [parts, setParts] = useState("")
    const [cat_battery, setCat_battery] = useState({}) //check the category if battery or not
    const navigate = useNavigate();
    const formData = new FormData();

    const vehicleCategoryList = [
        { id: "All", name: "All" },
        { id: "Premium Large", name: "Premium Large" },
        { id: "Premium Medium", name: "Premium Medium" },
        { id: "Medium Car", name: "Medium Car" },
        { id: "Small Car", name: "Small Car" },
        { id: "Large Car", name: "Large Car" },
        { id: "Medium Sedan", name: "Medium Sedan" },
        { id: "Medium SUV", name: "Medium SUV" },
        { id: "Medium MUV", name: "Medium MUV" },
        { id: "Small MUV", name: "Small MUV" },
    ];

    const [addOns, setAddOns] = useState('')
    const AddOnsList = [
        { id: "Valve Cups", name: "Valve Cups", price: "₹11578.00" },
        { id: "Valve Extension", name: "Valve Extension", price: "₹11578.00" }
    ];

    const vehicleSegmentList = [
        { id: 1, name: "Indian" },
        { id: 2, name: "Imported" },
    ];

    let link = FindLink(`/administrator/inventoryProduct`);

    const notifyAdd = () =>
        toast.success(
            <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                Product added successfully.
            </span>, {
            hideProgressBar: true,
            autoClose: 2200,
        }
        );

    const qry = {
        searchQry: "",
        page: 0,
        perPageItem: 150,
    };

    const {
        mutateAsync: addProduct,
        isSuccess: productAddSuccess,
        isLoading: invLoading,
    } = useAddProduct.add_product();

    const { mutateAsync: SelectAddOns } = useAddProduct.SelectAdd_ons(); //select add-ons

    const { mutateAsync: addImage, isLoading: imageLoading } =
        useAddProduct.addImage();

    const { data: catList } = useList.cat_List_SelectBox(qry);

    const {
        data: subCatList,
        isSuccess: subCatListSuccess,
        isLoading: subLoading,
    } = useList.subcat_list_by_category("", selectedCategory);

    const { data: brandList, isSuccess: brandsListSuccess } =
        useList.brand_by_category(
            selectedSubCategory ? selectedSubCategory : selectedCategory
        );

    useEffect(() => {
        setSelectedSubCategory(null);
        setBrandsList([]);
    }, [selectedCategory]);

    useEffect(() => {
        let catogery;
        if (categoryList && categoryList.length > 0 && selectedCategory) {
            catogery =
                categoryList && categoryList.find((e) => e.id === selectedCategory);

            if (catogery && catogery.type === 1) {
                setCatType("product");
            } else {
                setCatType("service");
            }
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (catList && catList && catList.length > 0) {
            const filterd = catList.filter((e) => {
                return e.parent_id === null;
            });
            setCategoryList([...filterd]);
        }
    }, [catList]);

    useEffect(() => {
        if (subCatList && subCatList.data && subCatList.data.length > 0) {
            setSubCategoriesList([...subCatList.data]);
        } else {
            setSubCategoriesList([]);
        }
    }, [subCatList, subCatListSuccess]);

    useEffect(() => {
        if (brandList && brandList.data && brandList.data.length > 0) {
            setBrandsList([...brandList.data]);
            setBrandID(null);
        } else {
            setBrandsList([]);
            setBrandID(null);
        }
    }, [brandList, brandsListSuccess]);

    // useEffect(() => {
    //   if (selectedCategory) {
    //     let selected;
    //     if (subCategoriesList && subCategoriesList.length > 0) {
    //       selected = subCategoriesList.find((item) => {
    //         return item.id === selectedSubCategory;
    //       });
    //     } else {
    //       selected = categoryList.find((item) => {
    //         return item.id === selectedCategory;
    //       });
    //     }

    //     let fields = [];
    //     if (
    //       selected &&
    //       selected.specification_fields &&
    //       selected.specification_fields !== null
    //     ) {
    //       fields =
    //         selected &&
    //         selected.specification_fields &&
    //         selected.specification_fields["SpecificationFields"];
    //     } else {
    //       fields = [];
    //     }
    //     if (fields.length > 0) {
    //       fields = fields
    //         .split(",")
    //         .map((e) => e.trim())
    //         .filter((e) => e !== "null");
    //       setSpecificationFields(
    //         fields.reduce(function (obj, v) {
    //           obj[v] = "";
    //           return obj;
    //         }, {})
    //       );
    //     } else {
    //       setSpecificationFields([]);
    //     }
    //   }
    // }, [selectedSubCategory, subCategoriesList]);

    useEffect(() => {
        if (selectedCategory) {
            let selected;
            let selectSub;
            if (subCategoriesList && subCategoriesList.length > 0) {
                selected = categoryList.find((item) => {
                    return item.id === selectedCategory;
                });
                selectSub = subCategoriesList.find((item) => {
                    return item.id === selectedSubCategory;
                });
            } else {
                selected = categoryList.find((item) => {
                    return item.id === selectedCategory;
                });
            }

            const catConverter = (selected = {}) => {
                let fields = [];
                if (
                    selected &&
                    selected.specification_fields &&
                    selected.specification_fields !== null
                ) {
                    fields =
                        selected &&
                        selected.specification_fields &&
                        selected.specification_fields["SpecificationFields"];
                } else {
                    fields = [];
                }
                if (fields.length > 0) {
                    fields = fields
                        .split(",")
                        .map((e) => e.trim())
                        .filter((e) => e !== "null");
                    return fields.reduce(function (obj, v) {
                        obj[v] = "";
                        return obj;
                    }, {});
                } else {
                    return {};
                }
            };

            if (
                Object.keys(catConverter(selectSub)).length > 0 ||
                Object.keys(catConverter(selected)).length > 0
            ) {
                setSpecificationFields({
                    ...catConverter(selected),
                    ...catConverter(selectSub),
                });
            }
        }
    }, [selectedSubCategory, subCategoriesList]);

    //Select add-ons
    const [searchText, setSearchText] = useState('');
    const [results, setResults] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState({
        searchQry: ''
    });

    const {
        data: productListAddons,
        isLoading: addOnsisLoading,
        isFetching: addOnsisFetching,
        refetch: ProductRefetch,
    } = productQuery.ProductList(searchQuery);

    // Update search query and refetch data on search text change
    useEffect(() => {
        const debouncedFetch = debounce(() => {
            if (searchText === '') {
                setResults([]);
            } else {
                setSearchQuery({
                    searchQry: searchText,
                });
                ProductRefetch()
                setResults(productListAddons?.data);
            }
        }, 500);

        debouncedFetch();

        return () => {
            debouncedFetch.cancel();
        };
    }, [searchText]);

    // Add or remove item from selected list
    const toggleSelectItem = (item) => {
        setSelectedItems((prevSelected) => {
            if (prevSelected.some((selected) => selected.id === item.id)) {
                return prevSelected.filter((selected) => selected.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    // Remove item from selected items
    const handleRemoveSelectedItem = (id) => {
        setSelectedItems((prevSelected) =>
            prevSelected.filter((item) => item.id !== id)
        );
    };

    // const submitData = async (e) => {
    //     e.preventDefault();

    //     let err = false;

    //     if (!selectedCategory) {
    //         err = true;
    //         setCategoryError("Category is required");
    //     } else {
    //         err = false;
    //     }

    //     // // if (!timeForService && timeForService.length === 0) {
    //     // //   err = true;
    //     // //   setTimeForServiceErr("Time for service is required");
    //     // // } else {
    //     // //   err = false;
    //     // // }

    //     if (hsn_code.length === 0 && !hsn_code) {
    //         err = true;
    //         setHsnCodeErr("HSN code is required");
    //     } else {
    //         err = false;
    //     }

    //     if (!brand_id || brand_id === "") {
    //         err = true;
    //         setBrandError("Brand is required");
    //     } else {
    //         err = false;
    //     }

    //     if (!vehicle_category || vehicle_category === "") {
    //         err = true;
    //         setVcatErr("Vehicle Category is required");
    //     } else {
    //         err = false;
    //     }

    //     // if (!vehicle_segment || vehicle_segment === "") {
    //     //     setVsectErr("Vehicle segment is required");
    //     //     err = true;
    //     // } else {
    //     //     err = false;
    //     // }

    //     if (catType === "service" && (!item_code || item_code === "")) {
    //         err = true;
    //         setItemCodeErr("Item code is required");
    //     } else {
    //         setItemCodeErr("");
    //     }

    //     if (!gst_perc || gst_perc === "") {
    //         setgstErr("GST percentage is required");
    //         err = true;
    //     } else {
    //         err = false;
    //     }

    //     if (catType !== "service" && (!series || series === "")) {
    //         err = true;
    //         setSeriesErr("Series is required");
    //     } else {
    //         setSeriesErr("");
    //     }

    //     // if (
    //     //   !hsn_code ||
    //     //   hsn_code === "" ||
    //     //   hsn_code.length !== 8 ||
    //     //   !/^\d+$/.test(hsn_code)
    //     // ) {
    //     //   setHsnCodeErr("HSN Code must contain 8 digits");
    //     //   err = true;
    //     // } else {
    //     //   err = false;
    //     // }

    //     if (
    //         !hsn_code ||
    //         hsn_code === "" ||
    //         hsn_code.length < 4 ||
    //         hsn_code.length > 8 ||
    //         !/^\d+$/.test(hsn_code)
    //     ) {
    //         err = true;
    //         setHsnCodeErr("HSN Code must contain between 4 and 8 digits");
    //     }

    //     if ((!mrp || mrp === 0) && (!rcp || rcp === 0)) {
    //         setMrpError("MRP / RCP is required");
    //         err = true;
    //     } else {
    //         err = false;
    //     }
    //     if ((!rcp || rcp === 0) && (!mrp || mrp === 0)) {
    //         setRcpError("MRP / RCP is required");
    //         err = true;
    //     } else {
    //         err = false;
    //     }
    //     if (!dealer_landing_price || dealer_landing_price === 0) {
    //         err = true;
    //         setDealerPriceError("Dealer Price is required");
    //     } else {
    //         err = false;
    //     }

    //     if (!customer_landing_price || customer_landing_price === 0) {
    //         err = true;
    //         setCustomerPriceError("Customer Price is required");
    //     } else {
    //         err = false;
    //     }

    //     Object.keys(specificationFileds)?.forEach((e) => {
    //         if (specificationFileds[e] === "" && parts != 'Parts' || !specificationFileds[e] && parts != 'Parts') {
    //             err = true
    //             setSpecError((prev) => {
    //                 if (specificationFileds[e] === "") {
    //                     return { ...prev, [e]: "This field is required" };
    //                 } else return { ...prev, [e]: "" };
    //             });
    //         } else if (parts == 'Parts') {
    //             err = false;
    //             setSpecError((prev) => {
    //                 return { ...prev, [e]: "" };
    //             });
    //         } else {
    //             err = false;
    //         }
    //     });

    //     Object.keys(warranty).forEach((e) => {
    //         setWarrentError((prev) => {
    //             return { ...prev, [e]: "" };
    //         });
    //     });
    //     if (!err) {
    //         addProduct({
    //             series: series,
    //             item_code: item_code,
    //             model: model,
    //             mrp: mrp === "" ? 0 : parseFloat(mrp),
    //             rcp: rcp === "" ? 0 : parseFloat(rcp),
    //             dealer_landing_price:
    //                 dealer_landing_price && parseFloat(dealer_landing_price),
    //             customer_landing_price:
    //                 customer_landing_price && parseFloat(customer_landing_price),
    //             gst_perc: gst_perc && parseFloat(gst_perc),
    //             margin: 1,
    //             description: description,
    //             customer_price_with_exchange:
    //                 customerExchangePrice !== ""
    //                     ? customerExchangePrice && parseFloat(customerExchangePrice)
    //                     : 0,
    //             dealer_price_with_exchange:
    //                 dealerExchangePrice !== ""
    //                     ? dealerExchangePrice && parseFloat(dealerExchangePrice)
    //                     : 0,
    //             features: { features: features },
    //             service_items: { 0: service_items },
    //             specifications: specificationFileds,
    //             warranty: warranty,
    //             vehicle_category: vehicle_category,
    //             unique_key: "",
    //             vehicle_segment: vehicle_segment,
    //             hsn_code: hsn_code,
    //             advantages: advantages,
    //             brand_id: brand_id,
    //         })
    //             .then((res) => {
    //                 if (res.status === 200) {
    //                     const id = res && res.data && res.data.data && res.data.data.id;
    //                     setHscCode('')
    //                     setMrp('')
    //                     setRcp('')
    //                     setDealerLandingPrice('')
    //                     setCustomerLandingPrice('')
    //                     setGstPerc('')
    //                     setAdvantages('')
    //                     setDescription('')
    //                     setItemCodeErr('')
    //                     SelectAddOns({
    //                         target_product_id: id,
    //                         addons_product_id: selectedItems?.map((o) => o?.id)
    //                     }).then((res) => {
    //                         if (res?.status == 200) {
    //                             setSelectedItems([])
    //                         }
    //                     })
    //                     // if (catType !== "service") {
    //                     Object.keys(imageData).forEach((e, i) => {
    //                         formData.append(`image${i + 1}`, imageData[e]);
    //                     });
    //                     if (imageData.length > 0) {
    //                         addImage({ id: id, image: formData }).then((res) => {
    //                             toast.success("Product added successfully", {
    //                                 hideProgressBar: true,
    //                                 autoClose: 2200,
    //                             });
    //                             // navigate(link);
    //                             setOpen(false)
    //                         });
    //                     } else {
    //                         // navigate(link);
    //                         setOpen(false)
    //                     }
    //                     // } else {
    //                     //     toast.success("Product added successfully", {
    //                     //         hideProgressBar: true,
    //                     //         autoClose: 2200,
    //                     //     });
    //                     // navigate(link);
    //                     //     setOpen(false)
    //                     // }
    //                 }
    //             })
    //             .catch((error) => {
    //                 if (
    //                     error &&
    //                     error.response &&
    //                     error.response.data &&
    //                     error.response.data.detail
    //                 ) {
    //                     navigate(link);
    //                 }
    //                 if (
    //                     error &&
    //                     error.response &&
    //                     error.response.data &&
    //                     error.response.data.error &&
    //                     error.response.data.error.item_code
    //                 ) {
    //                     toast.error(error.response.data.error.item_code[0], {
    //                         hideProgressBar: true,
    //                         autoClose: 2200,
    //                     });
    //                 }
    //             });
    //     }
    // }

    // console.log("pppppppp", catType, seriesErr)

    // useEffect(() => {
    //   if (productAddSuccess) {
    //     notifyAdd();
    //     handleClose();
    //   }
    // }, [productAddSuccess]);

    const validateField = (condition, setErrorFn, errorMessage) => {
        if (condition) {
            setErrorFn(errorMessage);
            return true;
        }
        setErrorFn("");
        return false;
    };

    useEffect(() => {
        setCategoryError("");
        setHsnCodeErr("");
        setSubCategoryError("");
        setItemCodeErr("");
        setBrandError("");
        setVcatErr("");
        setVsectErr("");
        setgstErr("");
        setMrpError("");
        setRcpError("");
        setSeriesErr("");
        setDealerPriceError("");
        setCustomerPriceError("");
        setSpecError({});
    }, [open])

    const submitData = async (e) => {
        e.preventDefault();

        let hasError = false;

        setCategoryError("");
        setHsnCodeErr("");
        setSubCategoryError("");
        setItemCodeErr("");
        setBrandError("");
        setVcatErr("");
        setVsectErr("");
        setgstErr("");
        setMrpError("");
        setRcpError("");
        setSeriesErr("");
        setDealerPriceError("");
        setCustomerPriceError("");
        setSpecError({});

        hasError |= validateField(!selectedCategory, setCategoryError, "Category is required");
        hasError |= validateField(!hsn_code || hsn_code.length < 4 || hsn_code.length > 8 || !/^\d+$/.test(hsn_code), setHsnCodeErr, "HSN Code must contain between 4 and 8 digits");
        hasError |= validateField(subCategoriesList.length > 0 && !selectedSubCategory, setSubCategoryError, "Sub Category is required");
        hasError |= validateField(
            catType === "service" && (!item_code || item_code.trim() === ""),
            setItemCodeErr,
            "Item code is required"
        );
        hasError |= validateField(!brand_id, setBrandError, "Brand is required");
        hasError |= validateField(!vehicle_category, setVcatErr, "Vehicle Category is required");
        hasError |= validateField(!vehicle_segment, setVsectErr, "Vehicle segment is required");
        hasError |= validateField(!gst_perc, setgstErr, "GST percentage is required");
        hasError |= validateField((!mrp && !rcp), (err) => {
            setMrpError(err);
            setRcpError(err);
        }, "MRP / RCP is required");
        hasError |= validateField(
            catType === "product" && (!series || series === ""),
            setSeriesErr,
            "Series is required"
        );
        hasError |= validateField(!dealer_landing_price, setDealerPriceError, "Dealer Price is required");
        hasError |= validateField(!customer_landing_price, setCustomerPriceError, "Customer Price is required");

        Object.keys(specificationFileds).forEach((key) => {
            if (!specificationFileds[key] && parts !== "Parts") {
                hasError = true;
                setSpecError((prev) => ({ ...prev, [key]: "This field is required" }));
            } else {
                setSpecError((prev) => ({ ...prev, [key]: "" }));
            }
        });

        if (hasError) return;

        try {
            const productData = {
                series: series ?? '',
                item_code: item_code,
                model: model,
                mrp: mrp === "" ? 0 : parseFloat(mrp),
                rcp: rcp === "" ? 0 : parseFloat(rcp),
                dealer_landing_price:
                    dealer_landing_price && parseFloat(dealer_landing_price),
                customer_landing_price:
                    customer_landing_price && parseFloat(customer_landing_price),
                gst_perc: gst_perc && parseFloat(gst_perc),
                margin: 1,
                description: description,
                customer_price_with_exchange:
                    customerExchangePrice !== ""
                        ? customerExchangePrice && parseFloat(customerExchangePrice)
                        : 0,
                dealer_price_with_exchange:
                    dealerExchangePrice !== ""
                        ? dealerExchangePrice && parseFloat(dealerExchangePrice)
                        : 0,
                features: { features: features },
                service_items: { 0: service_items },
                specifications: specificationFileds,
                warranty: warranty,
                vehicle_category: vehicle_category,
                unique_key: "",
                vehicle_segment: vehicle_segment,
                hsn_code: hsn_code,
                advantages: advantages,
                brand_id: brand_id,
            };

            const res = await addProduct(productData);

            if (res.status === 200) {
                setOpen(false)
                SelectAddOns({
                    target_product_id: id,
                    addons_product_id: selectedItems?.map((o) => o?.id)
                }).then((res) => {
                    if (res?.status == 200) {
                        setSelectedItems([])
                    }
                })
                // if (catType !== "service") {
                Object.keys(imageData).forEach((e, i) => {
                    formData.append(`image${i + 1}`, imageData[e]);
                });
                if (imageData.length > 0) {
                    addImage({ id: id, image: formData }).then((res) => {
                        toast.success("Product added successfully", {
                            hideProgressBar: true,
                            autoClose: 2200,
                        });
                        // navigate(link);
                        setOpen(false)
                    });
                } else {
                    // navigate(link);
                    setOpen(false)
                }
                // } else {
                //     toast.success("Product added successfully", {
                //         hideProgressBar: true,
                //         autoClose: 2200,
                //     });
                // navigate(link);
                //     setOpen(false)
                // }
            }
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                const message = data.detail || data.item_code?.[0] || "Something went wrong";
                const type = status === 500 ? "error" : "warn";
                toast[type](message, { hideProgressBar: true, autoClose: 2200 });
            }
        }
    };

    const handleClose = () => {
        // navigate(AppRoutes.ADMIN + EndPoint.INVENTORY_PRODUCT);
        // navigate(link);
        setOpen(false)
        setSelectedItems([])
    };

    const handleCategory = (e) => {
        setCategoryError("");
        setSelectedCategory(e.target.value);
    };


    const handleSubCategory = (e) => {
        setSubCategoryError("");
        setSelectedSubCategory(e.target.value);
    };

    useEffect(() => {
        const cat_battery_Check = categoryList?.find((o, i) => o?.id == selectedCategory)
        setCat_battery(cat_battery_Check)

        const partCheck = subCategoriesList?.find((o, i) => o?.id == selectedSubCategory)?.name
        setParts(partCheck)
    }, [selectedCategory, selectedSubCategory])

    const handleBrand = (e) => {
        setBrandError("");
        setBrandID(e.target.value);
    };

    const brandData = () => {
        if (selectedCategory === null && catList !== undefined) {
            return [{ name: "Please select catogery", id: 1, disable: true }];
        } else if (selectedSubCategory === null && subCategoriesList.length > 0) {
            return [{ name: "Please select subcatogery", id: 1, disable: true }];
        } else {
            return brandsList;
        }
    };

    console.log("modal_popup", open)

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalWrap">
                <div>
                    <Box>
                        <Box className={orderStyle.assignlinkbox}>
                            <Box></Box>
                            <Typography className={salesStyles.assignTitle}>
                                {title}
                            </Typography>
                            <Box className={orderStyle.assignlinkclose}>
                                <IconButton onClick={handleClose}>
                                    <AiOutlineClose />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </div>

                <div>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Category
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.SelectBox
                                        className={categoryStyle.formTextBar2}
                                        name="inventoryType"
                                        label="Select Category"
                                        options={categoryList}
                                        onChange={(e) => handleCategory(e)}
                                        error={categoryError}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {subCategoriesList.length > 0 && (
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={categoryStyle.label}>
                                            Sub Category
                                            <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Controls.SelectBox
                                            className={categoryStyle.formTextBar2}
                                            name="inventoryType"
                                            label="Select Sub Category"
                                            options={subCategoriesList}
                                            onChange={(e) => handleSubCategory(e)}
                                            error={subCategoryError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Brand
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.SelectBox
                                        className={categoryStyle.formTextBar2}
                                        name="inventoryType"
                                        label="Select Brand"
                                        options={brandData()}
                                        onChange={(e) => handleBrand(e)}
                                        error={brandError}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Vehicle Category
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.SelectBox
                                        className={categoryStyle.formTextBar2}
                                        name="inventoryType"
                                        label="Select Vehicle Category"
                                        options={vehicleCategoryList}
                                        onChange={(e) => {
                                            setVehicleCategory(e.target.value);
                                            setVcatErr("");
                                        }}
                                        error={vcatErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Vehicle Segment
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.SelectBox
                                        className={categoryStyle.formTextBar2}
                                        name="inventoryType"
                                        label="Select Vehicle Segment"
                                        options={vehicleSegmentList}
                                        onChange={(e) => {
                                            setVehicleSegment(e.target.value);
                                            // setVsectErr("");
                                        }}
                                        value={vehicle_segment}
                                        // error={vsecErr}
                                        defaultValue={1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {catType !== "service" && <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Series
                                        {catType !== "service" && catType != '' && <span style={{ color: "red" }}>*</span>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="series"
                                        placeholder="Series"
                                        onChange={(e) => {
                                            setSeries(e.target.value)
                                            setSeriesErr('')
                                        }}
                                        type="text"
                                        error={catType !== "service" && catType != '' && seriesErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Item Code
                                        {catType !== "product" && catType != '' && <span style={{ color: "red" }}>*</span>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="itemcode"
                                        placeholder="Item Code"
                                        onChange={(e) => {
                                            setItemCode(e.target.value);
                                            setItemCodeErr("");
                                        }}
                                        type="text"
                                        error={catType !== "product" && catType != '' && itemCodeErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Model
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="model"
                                        placeholder="Model"
                                        onChange={(e) => setModel(e.target.value)}
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        HSN Code
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="hsn_code"
                                        placeholder="HSN Code"
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, '');
                                            setHsnCodeErr("");
                                            setHscCode(value);
                                        }}
                                        type="text"
                                        value={hsn_code} // Ensure the value is controlled
                                        error={hsnCodeErr}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        MRP
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="mrp"
                                        inputProps={{ min: 0 }}
                                        placeholder="Eg. 1234"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setMrp(value);
                                            }
                                            if (value != 0) {
                                                setMrpError("");
                                                setRcpError("");
                                            }
                                        }}
                                        type="text"
                                        value={mrp}
                                        error={mrpError}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        RCP
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="RCP"
                                        inputProps={{ min: 0 }}
                                        placeholder="Eg. 1234"
                                        error={rcpError}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setRcp(value);
                                            }
                                            if (value != 0) {
                                                setRcpError("");
                                                setMrpError("");
                                            }
                                        }}
                                        type="text"
                                        value={rcp}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Dealer Price
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        inputProps={{ min: 0 }}
                                        name="dealer_landing_price"
                                        placeholder="Eg. 1234"
                                        // onChange={(e) => setDealerLandingPrice(e.target.value?.replace(/\D/g, ''))}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setDealerLandingPrice(value);
                                            }
                                        }
                                        }
                                        type="text"
                                        value={dealer_landing_price}
                                        error={dealerPriceError}
                                        onKeyDown={() => setDealerPriceError("")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Customer Price
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        name="customer_landing_price"
                                        inputProps={{ min: 0 }}
                                        placeholder="Eg. 1234"
                                        // onChange={(e) => setCustomerLandingPrice(e.target.value?.replace(/\D/g, ''))}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setCustomerLandingPrice(value);
                                            }
                                        }
                                        }
                                        type="text"
                                        value={customer_landing_price}
                                        error={customerPriceError}
                                        onKeyDown={() => setCustomerPriceError("")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {selectedCategory === 57 && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Customer price with exchange
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.InputField
                                                className={categoryStyle.formTextBar2}
                                                name="customer_price_with_exchange"
                                                placeholder="Eg. 2500"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const regex = /^[0-9]*\.?[0-9]*$/;
                                                    if (regex.test(value)) {
                                                        setcustomerExchangePrice(value);
                                                    }
                                                }
                                                }
                                                type="text"
                                                value={customerExchangePrice}
                                                error={dealerPriceError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Dealer price with exchange
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="dealer_price_with_exchange"
                                                placeholder={"Eg. 2500"}
                                                label="Eg. 2500"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const regex = /^[0-9]*\.?[0-9]*$/;
                                                    if (regex.test(value)) {
                                                        setdealerExchangePrice(value);
                                                    }
                                                }
                                                }
                                                type="text"
                                                value={dealerExchangePrice}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        GST Perc
                                        <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.InputField
                                        className={categoryStyle.formTextBar2}
                                        inputProps={{ min: 0 }}
                                        name="gst_perc"
                                        placeholder="Eg. 18"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (regex.test(value)) {
                                                setGstPerc(value)
                                                setgstErr("");
                                            }
                                        }}
                                        type="text"
                                        value={gst_perc}
                                        error={gstErr}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {Object.keys(specificationFileds).length > 0 &&
                            Object.keys(specificationFileds).map((item, idx) => {
                                return (
                                    <Grid key={idx} item xs={12} md={6}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} sm={4}>
                                                <Typography className={categoryStyle.label}>
                                                    {item[0].toUpperCase() + item.slice(1)}
                                                    {parts != 'Parts' && <span style={{ color: "red" }}>*</span>}                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Controls.InputField
                                                    className={categoryStyle.formTextBar2}
                                                    name={item}
                                                    placeholder={`Enter ${item}`}
                                                    inputProps={{ maxLength: 500 }}
                                                    error={parts != 'Parts' && specsError[item]}
                                                    onChange={(e) => {
                                                        setSpecificationFields({
                                                            ...specificationFileds,
                                                            [item]: e.target.value,
                                                        });
                                                        setSpecError({
                                                            ...specsError,
                                                            [item]: "",
                                                        });
                                                    }}
                                                    type="text"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Features
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.TextArea
                                        className={categoryStyle.Inventory_formtTextArea}
                                        name="features"
                                        placeholder="Eg. TR414 Tubeless Car Tyre Valve Stem Cap Good Quality"
                                        onChange={(e) => setFeatures(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Advantages
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.TextArea
                                        className={categoryStyle.Inventory_formtTextArea}
                                        name="advantages"
                                        placeholder="Eg. durability, excellent braking, superior performance"
                                        value={advantages}
                                        onChange={(e) => setAdvantages(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Controls.TextArea
                                        className={categoryStyle.Inventory_formtTextArea}
                                        name="description"
                                        value={description}
                                        placeholder="Eg. Advanced safety, excellent handling, brilliant traction and improved stability on all conditions."
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Specifications
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.TextArea
                        className={categoryStyle.textarea2}
                        name="specifications"
                        value={specifications}
                        label="Specifications"
                        onChange={(e) => setSpecifications(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

                        {catType === "service" && (
                            <>
                                {/* <Grid item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            Time for services
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name="timeForServices"
                            placeholder="Time for services"
                            value={timeForService}
                            onChange={(e) => {
                              setTimeForSErvices(e.target.value);
                              if (e.target.value != 0) {
                                setTimeForServiceErr("");
                              }
                            }}
                            type="text"
                            error={timeForServiceErr}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Services items
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.Inventory_formtTextArea}
                                                name="servicesItems"
                                                value={service_items}
                                                label="Services Items"
                                                onChange={(e) => setServiceItems(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {selectedCategory === 54 && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Warranty Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.Inventory_formtTextArea}
                                                name="Warranty type"
                                                placeholder="Warranty type"
                                                error={
                                                    warrentError["Warranty type"]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Warranty Years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="Warranty Years"
                                                placeholder="Warranty Years"
                                                error={
                                                    warrentError["Warranty Years"]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Manufacturing Defect Warranty Years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="Manufacturing Defect Warranty Years"
                                                placeholder="Manufacturing Defect Warranty Years"
                                                error={
                                                    warrentError[
                                                        "Manufacturing Defect Warranty Years"
                                                    ]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Warranty Duration
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="Warranty Duration"
                                                error={
                                                    warrentError["Warranty Duration"]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                placeholder="Warranty Duration"
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {selectedCategory === 53 || selectedCategory === 54 ? (
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        <Typography className={categoryStyle.label}>
                                            Warranty
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Controls.TextArea
                                            className={categoryStyle.textarea2}
                                            name="warranty"
                                            placeholder="Warranty"
                                            onChange={(e) => {
                                                setWarranty({
                                                    ...warranty,
                                                    [e.target.name]: e.target.value,
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : <></>}

                        {selectedCategory === 57 && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Free Warranty
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="warranty"
                                                error={
                                                    warrentError["Free Warranty"]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                placeholder="Free Warranty"
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Prorata Warranty
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="Prorata Warranty"
                                                error={
                                                    warrentError["Prorata Warranty"]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                placeholder="Prorata Warranty"
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Total Warranty
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="Total Warranty"
                                                placeholder="Total Warranty"
                                                error={
                                                    warrentError["Total Warranty"]
                                                        ? ""
                                                        : "This field is required"
                                                }
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Typography className={categoryStyle.label}>
                                                Warranty Duration
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Controls.TextArea
                                                className={categoryStyle.textarea2}
                                                name="Warranty Duration"
                                                placeholder="Warranty Duration"
                                                error={warrentError["Warranty Duration"]}
                                                onChange={(e) => {
                                                    setWarranty({
                                                        ...warranty,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {/* {catType !== "service" && ( */}
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography className={categoryStyle.label}>
                                        Images
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <IconButs
                                        ibtname={"Add image"}
                                        className={categoryStyle.imageButton}
                                        onClick={() => setImageOPen(true)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* )} */}

                        <Grid item xs={12} md={12} style={{ marginRight: "30px" }}>
                            <Grid container alignItems="flex-start">
                                <Grid item xs={12} sm={2}>
                                    <Typography className={categoryStyle.label}>
                                        Select Add-ons
                                    </Typography>
                                </Grid>
                                {/* Search Bar and Results Section */}
                                <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                >
                                    <TextField
                                        placeholder="Select Add-ons"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        // onChange={(e) => handleSearch(e)}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                addOnsisLoading || addOnsisFetching ? <CircularProgress position="end" style={{ marginRight: "5px" }} size={24} /> :
                                                    <InputAdornment position="end">
                                                        <img src={Search_Icon} style={{ marginRight: "5px" }} />
                                                    </InputAdornment>
                                            ),
                                        }}
                                    />

                                    {/* Results Cards */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxHeight: results?.length > 5 ? '400px' : 'auto',
                                            overflowY: results?.length > 5 ? 'auto' : 'visible',
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                                height: '40px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: '4px 0px 0px 0px',
                                                backgroundColor: '#444445',
                                                opacity: 0,
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                backgroundColor: 'transparent',
                                                gap: '0px',
                                            },
                                        }}
                                    >
                                        {results?.map((item) => (
                                            <Box
                                                key={item.id}
                                                sx={{
                                                    width: '100%',
                                                    border: "1px solid #D2D6DA",
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, padding: "8px" }}>
                                                    <Typography style={{ fontSize: '14px' }}>{item?.title}</Typography>
                                                    <Typography style={{ fontSize: '12px', color: "#989898" }}>{`₹ ${item?.mrp}`}</Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 5 }}>
                                                        <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.parent_id?.name}</Typography>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    display: 'inline-block',
                                                                    width: '6px',
                                                                    height: '6px',
                                                                    backgroundColor: "#E5E5E5",
                                                                    borderRadius: '50%',
                                                                }}
                                                            />
                                                            <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.name}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Checkbox
                                                    checked={selectedItems.some((selected) => selected.id === item.id)}
                                                    onChange={() => toggleSelectItem(item)}
                                                    sx={{
                                                        color: "#444445",
                                                        '&.Mui-checked': {
                                                            color: yellow[600],
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>

                                    {/* Selected Items Section */}
                                    {selectedItems?.length > 0 &&
                                        <Grid item xs={12} style={{ marginTop: searchText ? "20px" : "0px" }}>
                                            <Typography style={{ fontSize: '14px', color: "#989898" }}>Selected Items</Typography>
                                            <Grid container spacing={2} mt={1}>
                                                {selectedItems?.map((item) => (
                                                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '5px 2px',
                                                                position: 'relative',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '8px',
                                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                                width: '100%',
                                                                height: '100%',
                                                                minWidth: '100px',
                                                            }}
                                                        >
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, padding: "8px" }}>
                                                                <Typography style={{ fontSize: '14px' }}>{item?.title}</Typography>
                                                                <Typography style={{ fontSize: '12px', color: "#989898" }}>{`₹ ${item?.mrp}`}</Typography>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: 5 }}>
                                                                    <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.parent_id?.name}</Typography>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                                        <Box
                                                                            component="span"
                                                                            sx={{
                                                                                display: 'inline-block',
                                                                                width: '6px',
                                                                                height: '6px',
                                                                                backgroundColor: "#E5E5E5",
                                                                                borderRadius: '50%',
                                                                            }}
                                                                        />
                                                                        <Typography style={{ fontSize: '13px', color: "#989898" }}>{item?.brand_id?.category_id?.name}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <img
                                                                    src={closeSquare}
                                                                    onClick={() => handleRemoveSelectedItem(item.id)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: "5px",
                                                                        right: 5,
                                                                        cursor: "pointer"
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid xs={12} sm={6}>
                                    <Box
                                        sx={{
                                            display: { xs: "inline", sm: "flex" },
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButs
                                            ibtname={
                                                invLoading || imageLoading ? (
                                                    <CircularProgress size={24} />
                                                ) : (
                                                    FORM_BUTTON_MANAGEMENT
                                                )
                                            }
                                            className={categoryStyle.formButtonAdd}
                                            onClick={submitData}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box
                                        sx={{
                                            display: { xs: "inline", sm: "flex" },
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButs
                                            onClick={handleClose}
                                            className={categoryStyle.cancelButton}
                                            ibtname={FORMONE_BUTTON_MANAGEMENT}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <InventoryImage
                    open={imageOpen}
                    setOpen={setImageOPen}
                    imageData={setImageData}
                    data={[]}
                    newItem={true}
                    type='multiple_image'
                    title="Product Images"
                />
            </div>
        </Modal>
    )
}

export default AddInventoryProduct