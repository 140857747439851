import { Box, IconButton, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Typography } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import OrderManagmentStyle from "../Assign_Office_Advisor/OrderManagmentStyle";
import PlusIcon from "../../utilitis/icons/PlusIcon";
import "./InventoryExcel.css";
import useAddProduct from "../../data/inventory/useAddProduct";
import { object } from "yup";
const InventoryImage = ({
  open,
  setOpen,
  data,
  isView,
  imageData,
  newItem,
  setProductImages,
  setDeletedImages,
  type,
  title
}) => {
  const orderStyle = OrderManagmentStyle();
  const [images, setImages] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  useEffect(() => {
    if (data !== null && data !== undefined) {
      setImages(Object.values(data));
    }
  }, [data]);

  return (
    <Modal open={open} onClose={() => {
      setOpen(false)
      setImages([])
    }}>
      {newItem ? (
        <div className={orderStyle.imageViewer}>
          <div className="image-container">
            <Box className={orderStyle.assignlinkbox}>
              <Box></Box>
              <Typography className={orderStyle.assignTitle}>
                {title}
              </Typography>
              <Box className={orderStyle.assignlinkclose}>
                <IconButton onClick={() => setOpen(false)}>
                  <AiOutlineClose />
                </IconButton>
              </Box>
            </Box>

            {type == 'single_image'
              &&
              <>
                {
                  !isView && imgUrl.length < 1 ? (
                    <div className="imageAdd">
                      <label htmlFor="product_image">
                        <PlusIcon width="30px" height="30px" />
                      </label>
                      <input
                        type="file"
                        multiple={false}
                        name="productImages"
                        id="product_image"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          let file = e.target.files;
                          Object.keys(file).forEach((e) => {
                            let url = URL.createObjectURL(file[e]);
                            setImgUrl((prev) => [...prev, url]);
                          });
                          imageData((pre) => [...pre, ...file]);
                        }}
                      />
                    </div>
                  ) : null
                }
              </>
            }

            {type == 'multiple_image'
              &&
              <>
                {
                  !isView && imgUrl.length < 5 ? (
                    <div className="imageAdd">
                      <label htmlFor="product_image">
                        <PlusIcon width="30px" height="30px" />
                      </label>
                      <input
                        type="file"
                        multiple={false}
                        name="productImages"
                        id="product_image"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          let file = e.target.files;
                          Object.keys(file).forEach((e) => {
                            let url = URL.createObjectURL(file[e]);
                            setImgUrl((prev) => [...prev, url]);
                          });
                          imageData((pre) => [...pre, ...file]);
                        }}
                      />
                    </div>
                  ) : null
                }
              </>
            }

            {imgUrl.length > 0 ? (
              <div className="image-section-inventory">
                {imgUrl.map((items, index) => {
                  return (
                    <div className="img-container">
                      <img key={index} src={items} alt="product" />
                      <div className="remove-btn">
                        <IconButton
                          className="close"
                          onClick={() => {
                            setImgUrl((pre) => [
                              ...pre.slice(0, index),
                              ...pre.slice(index + 1, pre.length),
                            ]);
                            imageData((pre) => [
                              ...pre.slice(0, index),
                              ...pre.slice(index + 1, pre.length),
                            ]);

                          }}
                        >
                          <AiOutlineClose />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              isView &&
              data.length == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "100px",
                  }}
                >
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "300px", height: "300px" }}
                    loop
                    autoplay
                  ></lottie-player>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <>
          {type == "multiple_image"
            &&
            (
              <div className={orderStyle.imageViewer}>
                <div className="image-container">
                  <Box className={orderStyle.assignlinkbox}>
                    <Box></Box>
                    <Typography className={orderStyle.assignTitle}>
                      {title}
                    </Typography>
                    <Box className={orderStyle.assignlinkclose}>
                      <IconButton onClick={() => setOpen(false)}>
                        <AiOutlineClose />
                      </IconButton>
                    </Box>
                  </Box>

                  {!isView &&
                    (data.length > 0 && data && data[0] && data[0].length) + imgUrl.length < 5 ? (
                    <div className="imageAdd">
                      <label htmlFor="product_image">
                        <PlusIcon width="30px" height="30px" />
                      </label>
                      <input
                        type="file"
                        multiple={false}
                        name="productImages"
                        id="product_image"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          let file = e.target.files;
                          Object.keys(file).forEach((e) => {
                            let url = URL.createObjectURL(file[e]);
                            setImgUrl((prev) => [...prev, url]);
                          });
                          imageData((pre) => [...pre, ...file]);
                        }}
                      />
                    </div>
                  ) : null}

                  {/* multiple images view section start*/}
                  {
                    (!isView && images.length > 0) ||
                      (!isView && imgUrl.length > 0) ||
                      (data !== undefined && data && data[0] && data[0].length > 0) ? (
                      <div className="image-section-inventory">
                        {imgUrl?.map((items, index) => {
                          return (
                            <div className="img-container">
                              <img key={index} src={items} alt="product" />
                              <div className="remove-btn">
                                <IconButton
                                  className="close"
                                  onClick={() => {
                                    setImgUrl((pre) => [
                                      ...pre.slice(0, index),
                                      ...pre.slice(index + 1, pre.length),
                                    ]);
                                    // imageData((pre)=>[...pre.slice(0,index),...pre.slice(index+1,pre.length)]);
                                  }}
                                >
                                  <AiOutlineClose />
                                </IconButton>
                              </div>
                            </div>
                          );
                        })}
                        {/* multiple images view section end */}

                        {data !== undefined &&
                          data &&
                          data[0] &&
                          data[0]?.map((items, index) => {
                            return (
                              <div className="img-container">
                                <img
                                  key={index}
                                  src={process.env.REACT_APP_BASE_URL + data[1] + items}
                                  alt="product"
                                />
                                {!isView && <div className="remove-btn">
                                  <IconButton className="close"
                                    onClick={() => {
                                      // setImgUrl((pre) => [
                                      //   ...pre.slice(0, index),
                                      //   ...pre.slice(index + 1, pre.length),
                                      // ]);
                                      setDeletedImages((pre) => [...pre, ...data[0].filter((item, i) => i === index)])
                                      setProductImages((pre) => [[...pre[0].filter((e, i) => i !== index)], pre[1]]);
                                    }}
                                  >
                                    <AiOutlineClose />
                                  </IconButton>
                                </div>}
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      isView && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "100px",
                          }}
                        >
                          <lottie-player
                            src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                            background="transparent"
                            speed="1"
                            style={{ width: "300px", height: "300px" }}
                            loop
                            autoplay
                          ></lottie-player>
                        </div>
                      )
                    )
                  }
                </div>
              </div>
            )
          }

          {type == "single_image"
            &&
            (
              <div className={orderStyle.imageViewer}>
                <div className="image-container">
                  <Box className={orderStyle.assignlinkbox}>
                    <Box></Box>
                    <Typography className={orderStyle.assignTitle}>
                      {title}
                    </Typography>
                    <Box className={orderStyle.assignlinkclose}>
                      <IconButton onClick={() => setOpen(false)}>
                        <AiOutlineClose />
                      </IconButton>
                    </Box>
                  </Box>

                  {!isView &&
                    (data?.length > 0 && data && data?.length) + imgUrl.length == 0 ? (
                    <div className="imageAdd">
                      <label htmlFor="product_image">
                        <PlusIcon width="30px" height="30px" />
                      </label>
                      <input
                        type="file"
                        multiple={false}
                        name="productImages"
                        id="product_image"
                        hidden
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          let file = e.target.files;
                          Object.keys(file).forEach((e) => {
                            let url = URL.createObjectURL(file[e]);
                            setImgUrl((prev) => [...prev, url]);
                          });
                          imageData((pre) => [...pre, ...file]);
                        }}
                      />
                    </div>
                  ) : null}

                  {console.log("opasodfhsdbfn", imgUrl?.length)}

                  {/* multiple images view section start*/}
                  {
                    (!isView && images?.length > 0) ||
                      (!isView && imgUrl?.length > 0) ||
                      (data !== undefined && data && data?.length > 0) ? (
                      <div className="image-section-inventory">
                        {imgUrl?.map((items, index) => {
                          return (
                            <div className="img-container">
                              <img key={index} src={items} alt="product" />
                              <div className="remove-btn">
                                <IconButton
                                  className="close"
                                  onClick={() => {
                                    setImgUrl((pre) => [
                                      ...pre.slice(0, index),
                                      ...pre.slice(index + 1, pre.length),
                                    ]);
                                    // imageData((pre)=>[...pre.slice(0,index),...pre.slice(index+1,pre.length)]);
                                  }}
                                >
                                  <AiOutlineClose />
                                </IconButton>
                              </div>
                            </div>
                          );
                        })}
                        {/* multiple images view section end */}

                        {/* {data !== undefined &&
                          data &&
                          data?.map((items, index) => {
                            return (
                              <div className="img-container">
                                <img
                                  key={index}
                                  src={process.env.REACT_APP_BASE_URL + data[1] + items}
                                  alt="product"
                                />
                                {!isView && <div className="remove-btn">
                                  <IconButton className="close"
                                    onClick={() => {
                                      setDeletedImages((pre) => [...pre, ...data[0].filter((item, i) => i === index)])
                                      setProductImages((pre) => [[...pre[0].filter((e, i) => i !== index)], pre[1]]);
                                    }}
                                  >
                                    <AiOutlineClose />
                                  </IconButton>
                                </div>}
                              </div>
                            );
                          })} */}

                        {data !== undefined &&
                          Array.isArray(data) &&
                          data.map((item, index) => (
                            <div className="img-container" key={index}>
                              <img
                                src={process.env.REACT_APP_BASE_URL + item}
                                alt={`product-${index}`}
                              />
                              {!isView && (
                                <div className="remove-btn">
                                  <IconButton
                                    className="close"
                                    onClick={() => {
                                      setDeletedImages((prev) => prev?.filter((o, i) => i != index));
                                      setProductImages((prev) => prev?.filter((o, i) => i != index));
                                    }}
                                  >
                                    <AiOutlineClose />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          ))}

                      </div>
                    ) : (
                      isView && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "100px",
                          }}
                        >
                          <lottie-player
                            src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
                            background="transparent"
                            speed="1"
                            style={{ width: "300px", height: "300px" }}
                            loop
                            autoplay
                          ></lottie-player>
                        </div>
                      )
                    )
                  }
                </div>
              </div>
            )
          }
        </>
      )}
    </Modal>
  );
};

export default InventoryImage;
