//HEADER NAME
export const TXT_DASHBOARD = "Dashboard";
export const TXT_USERMANAGEMENT = "Customer Management";
export const TXT_DEALERMANAGEMENT = "Dealer / Mechanic Management";
export const TXT_DEALER_VIEW = "Dealer / Mechanic Details";
export const TXT_SALESPERSONMANAGEMENT = "Salesperson Management";
export const TXT_INVENTORYMANAGEMENT = "Inventory Management";
export const TXT_PRODUCTS = "Inventory Management/Products";
export const TXT_USERCREDENTIALMANAGEMENT = "User Credential Management";
export const TXT_USERROLEMANAGEMENT = "User Role Management";
export const TXT_OFFERMANAGEMENT = "Coupon Management";
export const TXT_WALLETMANAGEMENT = "Wallet Management";
export const TXT_REVIEWMANAGEMENT = "Review Management";
export const TXT_FINANCEMANAGEMENT = "Finance Management";
export const TXT_VEHICLEMANAGEMENT = "Vehicle Management";
export const TXT_ADVISORYOFICER = "Order Management";
export const TXT_ASSIGNEMERGENCY = "Order Management- Emergency Request";
export const TXT_REPORTING = "Reporting";

// *********** DETAIL PAGE VIEW HEADINGS ************//
export const TXT_DEALER_DETAIL = "Dealer / Mechanic Details";
export const TXT_DEALER_VIEW_DOCUMENTS =
  "Dealer / Mechanic Details  : View Documents";
export const TXT_DEALER_TECHNICIAN_ONBOARD =
  "Dealer/Technician Onboard Details";
export const TXT_SALES_DETAIL = "Salesperson Details";
export const TXT_SALES_DEALER_TECHNICIAN = "Sales Dealer/Technician Details";
export const TXT_TECH_ONBOARD_DETAIL = "Dealer/Technician Details";
export const TXT_USER_DETAIL = "Customer Details";
export const TXT_USER_EDIT = "Edit User";
export const TXT_VEHICLE_DETAIL = "View Vehicle Details";
export const TXT_VEHICLE_PRODUCT_DETAIL = "Add New Product / Related Product";
export const TXT_USER_ORDER_DETAIL = "User Order Details";
export const TXT_INVENTORY_USER_DETAIL = "Dealer/Technician Details";
export const TXT_ASSIGN_ORDER_DETAIL = "Order Details";
export const TXT_INVENTORY_PRODUCT_FORM = "Add Inventory";
export const TXT_PRODUCT_DETAIL = "Product Details";
export const TXT_ADD_EMERGENCY_REQUEST = "Add An Emergency Out Of Hour Request";
export const TXT_VEHICLE_EDIT = "Edit Vehicle";
export const TXT_VEHICLE_ADD = "Add Vehicle";
export const TXT_ORDER_ITEMS = "Order Items";
export const TXT_REQUEST_FROM_TECHNICIAN = "Request From Technician";

// *********** Add Forms Headings ***********//
export const ADD_DEALER = "Add Technician / Dealer";
export const EDIT_DEALERS = "Edit Technician / Dealer";
export const ADD_CATEGORY = "Apply";
export const ADD_USER = "Add User";
//***********Main headings *********//
export const TXT_CATEGORY = "Category";
export const TXT_CATEGORY_DETAIL = "View Category Details";
export const TXT_SUB_CATEGORY = "Sub Category";
export const TXT_SUB_CATEGORY_DETAIL = "View Sub Category Details";
export const TXT_BRAND = "Brand";
export const TXT_ADD_BRAND = "Add Brand";
export const TXT_EDIT_BRAND = "Edit Brand";
export const TXT_BRAND_DETAIL = "View Brand Details";
export const TXT_PRODUCT = "Product";
export const TXT_ADD_PRODUCT = "Add Product";
export const ADD_SALESPERSON = "Add Salesperson";
export const EDIT_SALESPERSON = "Edit Salesperson";

//// ICON BUTTON DETAILS
export const ICON_BUTTON_SALESPERSONMANAGEMENT = "Add Salesperson";
export const ICON_BUTTON_USERCREDENTIALMANAGEMENT = "Add User";
export const ICON_BUTTON_USERROLEMANAGEMENT = "Add Role";
export const ICON_BUTTON_OFFERMANAGEMENT = "Add Coupon";
export const ICON_BUTTON_REVIEWMANAGEMENT = "Last Week";
export const ICON_BUTTON_VEHICLEMANAGEMENT = "Add vehicle";
export const ICON_BUTTON_DEALERMANAGEMENT = "Add Dealer / Technician ";
export const ICON_BUTTON_INVENTORYMANAGEMENT = "Add Product / Service";
export const ICON_BUTTON_CATEGORY = "Add Category";
export const ICON_BUTTON_SUB_CATEGORY = "Add Sub Category";
export const ICON_BUTTON_BRAND = "Add Brand";
export const ICON_BUTTON_PRODUCT = "Add Product";
export const ICON_BUTTON_ADD_PRODUCT = "Upload Dealer Inventory In Bulk";
export const ICON_BUTTON_ADD_ITEM = "Add Item";
//// FORM PAGE DETAILS
export const FORM_BUTTON_MANAGEMENT = "Add";
export const FORM_EDIT_BUTTON = "EDIT";
export const INVENTORYFORM_ADD_DEALER = "ADD DEALER";
export const INVENTORYFORM_UPLOAD_DEALER = "UPLOAD DEALER";
export const ASSIGN_DEALER_TECHNICIAN = "OK";
export const FORM_ADDRESS_DETAILS = "SAVE";
export const FORMONE_BUTTON_MANAGEMENT = "Cancel";
export const FORMONE_EDIT_MANAGEMENT = "Edit";
export const ASSIGN_DEALER_FORM = "ADD EMERGENCY OOH REQUEST";
export const ASSIGN_FORM_MANAGEMENT = "ADD REQUEST";
export const DEALER_LIST_DOC = "View";
export const ADD_NEW = "Add New";
export const STEPPER_NEXT = "Next";
export const STEPPER_BACK = "Back";
export const DEALER_APPROVE = "Approve";
export const SUBMIT = "SUBMIT";
export const DEALER_LIST_DOC_DOWNLOAD = "Download";
export const ADD_EMERGENCY_REQUEST = "Add Request";
export const EDIT_BRAND = "Apply";
//HEADER LIST
export const SALES_USER_DETAILS = "Sales Dealer / Technicians";

//IMPORT EXCEL DATA
export const EXCEL_DATA = "IMPORT DATA";
export const EXCEL_UPLOAD_DATA = "UPLOAD DATA";
export const INVENTORY_MODAL_HEADER = "Upload Your Inventory Data";
export const ADD_VEHICLE = "Add Vehicle";

//BANK DETAILS
export const BANK_DETAIL_DATA = "Click to add bank details";

///DOCUMENT LIST

export const DOC_BANK_PASSBOOK = "Bank Passbook";
export const DOC_AADHAR_CARD = "Aadhar Card";
export const DOC_CHEQUE = "Cancelled Cheque";
export const DOC_GST = "GST Certificate";
export const DOC_PAN_CARD = "PAN Card";
export const DOC_SHOP = "Shop Banner";
