import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import {
  TXT_CATEGORY,
  TXT_SUB_CATEGORY,
  ICON_BUTTON_CATEGORY,
  ICON_BUTTON_SUB_CATEGORY,
} from "../../utilitis/header";
import useTable from "../../sharedFeatures/useTable";
import IconButs from "../../common/Controls/IconButs";
import Controls from "../../common/Controls/Controls";
import HeadCells from "../../common/Headcells";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import categoryBrandStyles from "./InventoryManagmentStyle";
import { AiOutlineEye } from "react-icons/ai";
import HeadBar from "../../components/HeadBar/HeadBar";
import makeStyles from "../../sharedFeatures/sharedClasses";
import { BsSearch } from "react-icons/bs";
import { useForm } from "../../sharedFeatures/useForm";
import AddCategory from "./AddCategory";
import useAddCategory from "../../data/inventory/useAddCategory";
import useList from "../../data/inventory/useList";
import CustomLoader from "../../features/CustomLoader";
import PageCounter from "../../common/PageCounter";
import { Pagination } from "@material-ui/lab";
import usePagination from "../../common/Controls/usePagination";
import DropDown from "../../common/Controls/ListingDropdown";
import { useParams } from "react-router-dom";
import financeStyles from "../FinanceManagement/financeStyles";
import DialogBox from "../../common/Controls/DialogBox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../DealerManagement/AddEditDealer/toast.css";
import SpecificationKeys from "./SpecificationKeys";
import NavigateLink from "../../common/Controls/NavigateLink";
import useAddProduct from "../../data/inventory/useAddProduct";
const SubCategory = () => {
  const styles = makeStyles();

  const params = useParams();

  const customStyle = categoryBrandStyles();

  const financeStyle = financeStyles();

  const notifyAdd = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Category added successfully.
      </span>, {
      hideProgressBar: true,
      autoClose: 2200,
    }
    );

  const notifySubAdd = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Sub Category added successfully.
      </span>, {
      hideProgressBar: true,
      autoClose: 2200,
    }
    );

  const notifyEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Category updated successfully.
      </span>, {
      hideProgressBar: true,
      autoClose: 2200,
    }
    );

  const notifySubEdit = () =>
    toast.success(
      <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
        Sub Category updated successfully.
      </span>, {
      hideProgressBar: true,
      autoClose: 2200,
    }
    );

  //aimee
  // const [searchParams] = useSearchParams();
  const searchParams = new URLSearchParams(window.location.search);
  const searchp = searchParams.get("search");
  const itemsno = searchParams.get("itemsno");
  const page = searchParams.get("page");
  const [search, setSearch] = useState(searchp !== null ? searchp : "");
  const [pageItem, setPageItem] = useState(itemsno !== null ? itemsno : 10);
  const [currentpageNo, setcurrentpageNo] = useState(page !== null ? page : 1);
  const pathname = useLocation().pathname
  // const [search, setSearch] = useState("");
  // const [currentpageNo, setcurrentpageNo] = useState(1);
  // const [pageItem, setPageItem] = useState(10);

  const [select, setSelect] = useState("");
  const [catId, setCatId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState();
  const [userId, setUserId] = useState("");

  const [catData, setCatData] = useState("");

  const [typeError, setTypeError] = useState("");
  const [nameError, setNameError] = useState("");
  const [otherChargeError, setOtherChargeError] = useState("");
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [processingTimeError, setProcessingTimeError] = useState("");
  const [timeslotDayError, setTimeslotDayError] = useState("");
  const [timeslotNightError, setTimeslotNightError] = useState("");
  const [openKey, setOpenKey] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [productImages, setProductImages] = useState([])
  const [deletedImages, setDeletedImages] = useState([]);
  const handlecreateNewItem = (e) => {
    setPageItem(e.target.value);
  };

  const qry = {
    searchQry: search,
    page: page !== null ? page : currentpageNo, //aimee
    perPageItem: pageItem,
  };

  const {
    data: catList,
    isLoading,
    isFetching,
    isError,
  } = useList.subcat_list_by_category(
    qry,
    params.id
  )


  const {
    mutateAsync: addCategory,
    isSuccess: addCategorySuccess,
    reset: resetAdd,
  } = useAddCategory.add_cat(params.id);

  const { mutateAsync: SelectAddOns } = useAddProduct.SelectAdd_ons(); //select add-ons

  const {
    mutateAsync: editCategory,
    isSuccess: editCategorySuccess,
    reset: resetEdit,
  } = useAddCategory.edit_cat(params.name);

  const { totalCount } = PageCounter(catList && catList.count);

  const { currentData, currentPage, maxPage, slNo, setSlNo, setSearchParams, setCurrentPage } = usePagination(
    catList !== undefined && catList,
    pageItem
  );

  const { TblContainer, TblHead } = useTable(
    HeadCells.categoryHead,
    totalCount
  );

  useEffect(() => {
    setcurrentpageNo(1);
  }, [search]);

  useEffect(() => {
    setcurrentpageNo(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (currentpageNo > maxPage) {
      setcurrentpageNo(maxPage);
    }
  }, [pageItem]);

  const initialValues = {
    name: "",
    usp: "",
    other_charges: "",
    type: "",
    icon: "",
    specification: "",
    part_type: "",
    service_type: "",
    night_service: "",
    time_slots: {},
    time_slots_day: "",
    time_slots_night: "",
    display: false,
    processing_time: "",
  };

  const {
    handleOpen,
    open,
    values,
    setValues,
    handleInputChange,
    errors,
    messageOpen,
    messageContent,
    id,
    handleCheckBox,
  } = useForm(initialValues);

  useEffect(() => {
    if (!open) {
      setNameError("");
      setOtherChargeError("");
      setTypeError("");
      setServiceTypeError("");
      setTimeslotDayError("");
      setTimeslotNightError("");
      setProcessingTimeError("")
    }
  }, [open]);

  const submitData = async (e) => {
    e.preventDefault();

    let err = null;
    if (!values.type) {
      setTypeError("Type is required");
      err = true;
    }
    if (!values.name) {
      setNameError("Name is required");
      err = true;
    }
    // if (!values.other_charges) {
    //   setOtherChargeError("Other Charge is required");
    //   err = true;
    // }
    if (isNaN(parseInt(values.service_type))) {
      setServiceTypeError("Service Type is required");
      err = true;
    }
    if (values.time_slots_day != "8:00-19:59") {
      setTimeslotDayError("Day slot must be 8:00-19:59");
      err = true;
    }
    if (values.time_slots_night != "20:00-7:59") {
      setTimeslotNightError("Night slot must be 20:00-7:59");
      err = true;
    }
    if (values.processing_time >= 1 && values.processing_time <= 100) {
      setProcessingTimeError("");
      err = false;
    } else if (values.processing_time === "" || values.processing_time === null || values.processing_time === undefined) {
      setProcessingTimeError("");
      err = false;
    } else {
      setProcessingTimeError("Ensure this value is between 1 - 100");
      err = true;
    }
    if (!err) {
      if (typeof id == "number") {
        editCategory({
          id: id,
          name: values.name,
          usp: values.usp,
          other_charges: values.other_charges,
          type: values.type,
          parent_id:
            params.id !== undefined && params.id !== null
              ? parseInt(params.id)
              : "",
          part_type: values.part_type,
          service_type: values.service_type,
          night_service: values.night_service,
          time_slots: {
            day: values.time_slots_day,
            night: values.time_slots_night,
          },
          display: values.display,
          processing_time: values.processing_time
        })
          .then((res) => {
            if (res.status === 200) {
              SelectAddOns({
                target_category_id: id,
                addons_product_id: selectedItems?.map((o) => o?.addons_product_id ? o?.addons_product_id?.id : o?.id)
              })
              if (params.id) {
                notifySubEdit();
                resetAdd();
              } else if (!params.id) {
                notifyEdit();
                resetAdd();
              }
            }
          })
          .catch((err) => {
            if (err && err.response && err.response.status !== 403) {
              toast.error("Something went wrong", {
                hideProgressBar: true,
                autoClose: 2200,
              });
            }
          });
      } else {
        addCategory({
          name: values.name,
          usp: values.usp,
          other_charges: values.other_charges,
          type: values.type,
          parent_id:
            params.id !== undefined && params.id !== null
              ? parseInt(params.id)
              : "",
          part_type: values.part_type,
          service_type: values.service_type,
          night_service: values.night_service ? values.night_service : false,
          time_slots: {
            day: values.time_slots_day,
            night: values.time_slots_night,
          },
          display: values.display,
          processing_time: values.processing_time
        })
          .then((res) => {
            if (res.status == 201) {
              const id = res && res.data && res.data.id;
              SelectAddOns({
                target_category_id: id,
                addons_product_id: selectedItems?.map((o) => o?.id)
              }).then((res) => {
                if (res?.status == 200) {
                  setSelectedItems([])
                }
              })
              if (params.name) {
                if (res.status === 201) {
                  notifySubAdd();
                  resetAdd();
                }
              } else if (!params.name) {
                if (res.status === 201) {
                  notifyAdd();
                  resetAdd();
                }
              }
            }
          })
          .catch((err) => {
            if (err && err.response && err.response.status !== 403) {
              toast.error("Something went wrong", {
                hideProgressBar: true,
                autoClose: 2200,
              });
            }
          });
      }
      handleOpen();
    }
  };

  // useEffect(() => {
  //    if(!open){
  //     setNameError(false)
  //     setTypeError(false)
  //     setOtherChargeError(false)
  //    }
  //   if (editCategorySuccess && params.id) {
  //     notifySubEdit();
  //     resetEdit();
  //   } else if (editCategorySuccess && !params.id) {
  //     notifyEdit();
  //     resetEdit();
  //   } else if (addCategorySuccess && !params.name) {
  //     notifyAdd();
  //     resetAdd();
  //   } else if (addCategorySuccess && params.name) {
  //     notifySubAdd();
  //     resetAdd();
  //   }
  // }, [addCategorySuccess, editCategorySuccess,open ]);

  let serialNo = pageItem * slNo;

  const handleDelete = (id) => {
    setUserId(id);
    setDeleteOpen(true);
  };

  const handleKeys = (id) => {
    setCatId(id);
    setOpenKey(!openKey);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const {
    mutateAsync: remove,
    data: deletedData,
    isSuccess: deletedSuccess,
  } = useAddCategory.delete_cat();

  const handleRemove = async () => {
    if (userId) remove(userId).then(res => {
      toast.success(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          Subcategory deleted successfully.
        </span>, {
        hideProgressBar: true,
        autoClose: 2200,
      }
      );
      setDeleteOpen(false);

    }).catch(err => {
      toast.err(
        <span style={{ fontFamily: "Montserrat", fontSize: "12px" }}>
          Something went wrong.
        </span>, {
        hideProgressBar: true,
        autoClose: 2200,
      }
      );
    })
  };

  useEffect(() => {
    if (catList) {
      if (params.id !== undefined && params.id !== null) {
        setCatData(catList.data);
      } else {
        setCatData(catList.data);
      }
    } else {
      setCatData("");
    }
  }, [catList, params.id, deletedSuccess]);

  return (
    <>
      <HeadBar
        title={
          params.id !== undefined && params.id !== null
            ? `${params.name} - ${TXT_SUB_CATEGORY}`
            : TXT_CATEGORY
        }
      />
      <div className={styles.divStyle}>
        <div className={customStyle.searchBoxParent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Controls.FullWidthSearch
                placeholder="Search"
                className={styles.searchBar}
                name="usersearch"
                value={search}
                onChange={(e) => {
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  setSearch(e.target.value)
                  setSlNo(0)
                  setCurrentPage(1)
                }

                }
                icon={
                  <BsSearch style={{ marginLeft: "5px", color: "#67748E" }} />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", md: "end" },
                }}
              >
                <IconButs
                  ibtname={
                    params.id !== undefined && params.id !== null
                      ? ICON_BUTTON_SUB_CATEGORY
                      : ICON_BUTTON_CATEGORY
                  }
                  icon={<AiOutlinePlus />}
                  className={customStyle.dmSalesButtonOne}
                  onClick={handleOpen}
                />
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className={styles.tableBox}>
          <section>
            {messageOpen ? (
              <>
                <div>
                  <Controls.MessageBox message={messageContent} />
                </div>
              </>
            ) : (
              ""
            )}
            <TblContainer>
              <TblHead />
              {isLoading || isFetching ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <CustomLoader />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {catData &&
                    catData.map((item, key) => {
                      serialNo++;
                      //aimee
                      return (
                        <TableRow key={key}>
                          <TableCell align="center">{serialNo}</TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">
                            {item.other_charges}
                          </TableCell>
                          <TableCell align="center">
                            {item.type === 1 ? "Product" : "Service"}
                          </TableCell>
                          <TableCell align="center" style={{ width: "15rem" }}>
                            {item.usp}
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={
                                params.name !== undefined &&
                                  params.name !== null
                                  ? {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }
                                  : {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }
                              }
                            >
                              {/* <Link
                                to={
                                  params.name !== undefined &&
                                  params.name !== null
                                    ? `/administrator/category/categoryDetails/${item.id}/${params.name}`
                                    : `/administrator/category/categoryDetails/${item.id}`
                                }
                              >
                                <IconButton>
                                  <AiOutlineEye className="user-icons" />
                                </IconButton>
                              </Link> */}

                              <NavigateLink
                                url={
                                  params.name !== undefined &&
                                    params.name !== null
                                    ? `/administrator/category/categoryDetails/${item.id}/${params.name}`
                                    : `/administrator/category/categoryDetails/${item.id}`
                                }
                                styleclass={styles.linkColor}
                                currentPage={currentPage}
                                trySearch={search}
                                pageItems={pageItem}
                                itemid={item.id}
                                display={
                                  <IconButton>
                                    <AiOutlineEye className="user-icons" />
                                  </IconButton>
                                }
                              />
                              <IconButton onClick={() => handleOpen(item.id)}>
                                <AiOutlineEdit className="user-icons" />
                              </IconButton>
                              {params.id !== undefined && params.id !== null ? (
                                <>
                                  <IconButton
                                    onClick={() => handleDelete(item.id)}
                                  >
                                    <AiOutlineDelete className="user-icons" />
                                  </IconButton>
                                </>
                              ) : null}
                              {/* {
                                <IconButton>
                                  <AiFillDelete className="user-icons" />
                                </IconButton>
                              } */}
                            </Box>
                          </TableCell>

                          {params.id !== undefined && params.id !== null ? (
                            ""
                          ) : (
                            <TableCell align="center">
                              <Link
                                to={`/administrator/category/${item.id}/${item.name}`}
                              >
                                SubCategory
                              </Link>
                            </TableCell>
                          )}
                          <TableCell>
                            <Link
                              onClick={() => handleKeys(item.id)}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Specification Key
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </TblContainer>
          </section>
        </div>

        {((catData && catData.length === 0) || isError) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_agnejizn.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
        {open ? (
          <AddCategory
            open={open}
            handleClose={handleOpen}
            title={
              isNaN(id)
                ? params.id
                  ? `Add Sub Category for ${params.name}`
                  : "Add Category"
                : params.name
                  ? "Edit Sub Category"
                  : "Edit Category"
            }
            modalTitle='Sub_Category'
            values={values}
            handleInputChange={handleInputChange}
            handleCheckBox={handleCheckBox}
            errors={errors}
            // validate={validate}
            submitData={submitData}
            setValues={setValues}
            id={typeof id == "number" ? id : ""}
            nameError={nameError}
            setNameError={setNameError}
            otherChargeError={otherChargeError}
            setOtherChargeError={setOtherChargeError}
            typeError={typeError}
            setTypeError={setTypeError}
            serviceTypeError={serviceTypeError}
            setServiceTypeError={setServiceTypeError}
            timeslotDayError={timeslotDayError}
            setTimeslotDayError={setTimeslotDayError}
            timeslotNightError={timeslotNightError}
            setTimeslotNightError={setTimeslotNightError}
            processingTimeError={processingTimeError}
            setProcessingTimeError={setProcessingTimeError}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            productImages={productImages}
            setProductImages={setProductImages}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
          />
        ) : (
          ""
        )}
        {openKey ? (
          <SpecificationKeys
            openKey={openKey}
            handleKeys={handleKeys}
            catId={catId}
            values={values}
            setValues={setValues}
            handleInputChange={handleInputChange}
            errors={errors}
            params={params}
          />
        ) : null}

        <div className={financeStyle.pagination}>
          <div className={financeStyle.paginationStyle}>
            <DropDown
              value={pageItem}
              handlecreateNewItem={handlecreateNewItem}
              style={{ width: "90px", borderRadius: "55px", height: "34px" }}
              items={[
                { key: 1, value: 10 },
                { key: 2, value: 20 },
                { key: 3, value: 30 },
              ]}
            />
            <span className={financeStyle.paginationPage}>Per Page</span>
          </div>
          <div className={customStyle.paginationNext}>
            <Pagination
              count={maxPage}
              page={currentPage}
              onChange={currentData}
              variant="outlined"
              className={financeStyle.paginationFont}
            />
          </div>
        </div>
        {deleteOpen ? (
          <DialogBox
            open={deleteOpen}
            setOpen={setDeleteOpen}
            handleClose={handleDeleteClose}
            content="Are you sure you want to delete this Subcategory?"
            userId={userId}
            handleRemove={handleRemove}
          />
        ) : null}
      </div>
    </>
  );
};

export default SubCategory;
