import { Box, Grid, IconButton, Paper } from "@material-ui/core";
import { Typography } from "antd";
import React, { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Controls from "../../common/Controls/Controls";
import HeadBar from "../../components/HeadBar/HeadBar";
import BackbuttonIcon from "../../utilitis/BackbuttonIcon";
import makeStyles from "../../sharedFeatures/sharedClasses";
import categoryBrandStyles from "./InventoryManagmentStyle";
import IconButs from "../../common/Controls/IconButs";
import Sub from "../../common/Subheader/Sub";

import { useForm } from "../../sharedFeatures/useForm";
import productQuery from "../../data/inventory/useProductList";
import { useEffect } from "react";

import { DEALER_LIST_DOC } from "../../utilitis/header";
import InventoryImage from "./inventoryImage";
import { FindLink } from "../../sharedFeatures/FindLink";

const ProductViewDetails = () => {
  const viewStyle = makeStyles();
  const [imageOpen, setImageOPen] = useState(false);
  const categoryStyle = categoryBrandStyles();
  const [producttype, setProductType] = useState("");
  const { id } = useParams();
  const { data: productDetails } = id ? productQuery.productListById(id) : "";
  const initialValues = {
    brand:  '',
      inventoryId: '',
      category:'',
      subCatogary:'',
      series:'',
      title:'',
      status:'',
      zohoId: '',
      customerPrice:'',
      dealerPrice: '',
      margin: '',
      discription: '',
      features: '',
      advantages: '',

      inventoryName:'',
      mrp: '',
      rcp: '',
      customerPrice: '',
      DealerPrice: '',
      gst: '',
      description: '',
      vehicleCategory: '',
      warranty:'',
      model: '',
      vehicleSegment:'',
      otherCharge:'',
      hsn: '',
      servicesItems:'',
  };

  
  
  let link = FindLink("/administrator/inventoryProduct");
 

  const { values, setValues } = useForm(initialValues);
  const [specification, setSpecification] = useState([]);
  useEffect(() => {
    setValues({
      ...values,
      brand:
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.name,
      inventoryId: productDetails && productDetails.item_code,
      category:
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.parent_id
          ? productDetails &&
            productDetails.brand_id &&
            productDetails.brand_id.category_id &&
            productDetails.brand_id.category_id.parent_id.name
          : productDetails &&
            productDetails.brand_id &&
            productDetails.brand_id.category_id &&
            productDetails.brand_id.category_id.name,
      subCatogary:
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.parent_id &&
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.name,
      series: productDetails && productDetails.series,
      title: productDetails && productDetails.title,
      status:
        productDetails && productDetails.status === true
          ? "Active"
          : "Inactive",
      zohoId: productDetails && productDetails.zoho_item_id,
      customerPrice: productDetails && productDetails.customer_landing_price,
      dealerPrice: productDetails && productDetails.dealer_landing_price,
      margin: productDetails && productDetails.margin,
      discription: productDetails && productDetails.description,
      features: productDetails && productDetails.features && productDetails.features.features && productDetails.features.features,
      advantages: productDetails && productDetails.advantages,

      inventoryName:
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.name,
      mrp: productDetails && productDetails.mrp === null ?0:productDetails && productDetails.mrp ,
      rcp: productDetails && productDetails.rcp === null ? 0 :productDetails && productDetails.rcp,
      customerPrice: productDetails && productDetails.customer_landing_price,
      DealerPrice: productDetails && productDetails.dealer_landing_price,
      gst: productDetails && productDetails.gst_perc,
      description: productDetails && productDetails.description,
      vehicleCategory: productDetails && productDetails.vehicle_category,
      warranty:
        productDetails &&
        productDetails.warranty &&
        productDetails.warranty,
      model: productDetails && productDetails.model,
      vehicleSegment: productDetails && productDetails.vehicle_segment_name,
      otherCharge:
        productDetails &&
        productDetails.brand_id &&
        productDetails.brand_id.category_id &&
        productDetails.brand_id.category_id.name,
      hsn: productDetails && productDetails.hsn_code,
      servicesItems:
        productDetails &&
        productDetails.service_items &&
        productDetails.service_items[0],
    });

    if (
      productDetails &&
      productDetails.brand_id &&
      productDetails.brand_id.category_id &&
      productDetails.brand_id.category_id.type === 1
    ) {
      setProductType(
        'Product'
      );
    } else if (
      productDetails &&
      productDetails.brand_id &&
      productDetails.brand_id.category_id &&
      productDetails.brand_id.category_id.type === 2
    ) {
      setProductType(
        "Services"
      );
    } 

    if (productDetails && productDetails.specifications) {
      setSpecification(productDetails.specifications);
    }
    const vehicleSegmentList = [
      { id: 1, name: "Indian" },
      { id: 2, name: "Imported" },
    ];
  }, [productDetails]);

  return (
    <>
      <HeadBar title="Product Details" />
      <div className={viewStyle.divStyle}>
        <Paper className={categoryStyle.parentContainer} elevation="6">
          <div className={viewStyle.subHeaderParent}>
            <Box sx={{ display: { xs: "none", sm: "inline" } }}>
              <Link to={link}>
                <IconButton className={viewStyle.backButtonParent}>
                  <BackbuttonIcon />
                </IconButton>
              </Link>
            </Box>
            <Sub data="Product Details" />
          </div>
          <div>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Catogery
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="catogery"
                      placeholder="Catogery"
                      value={values.category}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {productDetails &&
                productDetails.brand_id &&
                productDetails.brand_id.category_id &&
                productDetails.brand_id.category_id.parent_id && (
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Sub catogery
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.InputField
                          className={categoryStyle.formTextBar2}
                          name="subCatogery"
                          placeholder="Sub catogery"
                          value={values.subCatogary}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Brand
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="brand"
                      placeholder="Brand"
                      value={values.brand}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Vechicle catogery
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="vehicleCatogery"
                      placeholder="Vechicle catogery"
                      value={values.vehicleCategory}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {producttype !== "Services" && (
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography className={categoryStyle.label}>
                        Vehicle Segment
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controls.InputField
                        className={categoryStyle.formTextBar2}
                        name="vehicleSegment"
                        placeholder="Vehicle Segment"
                        value={values.vehicleSegment}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Item Code
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="itemcode"
                      placeholder="Item Code"
                      value={values.inventoryId}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {producttype !== "Services" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Series
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.InputField
                          className={categoryStyle.formTextBar2}
                          name="series"
                          placeholder="Series"
                          value={values.series}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Model
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.InputField
                          className={categoryStyle.formTextBar2}
                          name="model"
                          placeholder="Model"
                          value={values.model}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Title
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="title"
                      placeholder="Title"
                      value={values.title}
                      type="text"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="status"
                      placeholder="Status"
                      value={values.status}
                      type="text"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Zoho id
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="zohoId"
                      placeholder="Zoho id"
                      value={values.zohoId}
                      type="text"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      HSN Code
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="hsn_code"
                      placeholder="HSN Code"
                      value={values.hsn}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      GST Perc
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="gst_perc"
                      placeholder="GST Perc"
                      value={values.gst + " %"}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>MRP</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="mrp"
                      placeholder="INR 1234"
                      value={values.mrp + " INR"}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>RCP</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="RCP"
                      placeholder="rcp"
                      value={values.rcp + " INR"}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Customer Price
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="customer_landing_price"
                      placeholder="INR 1234"
                      value={values.customerPrice + " INR"}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Dealer Price
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="dealer_landing_price"
                      placeholder="INR 1234"
                      value={values.dealerPrice + " INR"}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Margin
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.InputField
                      className={categoryStyle.formTextBar2}
                      name="margin"
                      placeholder="Margin"
                      value={values.margin}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.TextArea
                      className={categoryStyle.textarea2}
                      name="description"
                      value={values.discription}
                      label="Description"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Features
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.TextArea
                      className={categoryStyle.textarea2}
                      name="features"
                      value={values.features}
                      label="Features"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Advantages
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Controls.TextArea
                      className={categoryStyle.textarea2}
                      name="advantages"
                      label="Advantages"
                      value={values.advantages}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {producttype === "Services" && (
                <>
                  {/* <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Time for services
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.InputField
                          className={categoryStyle.formTextBar2}
                          name="timeForServices"
                          placeholder="Time for services"
                          value={""}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography className={categoryStyle.label}>
                          Services items
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Controls.TextArea
                          className={categoryStyle.textarea2}
                          name="servicesItems"
                          value={values.servicesItems}
                          label="Services Items"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {Object.keys(specification).length > 0 &&
                Object.keys(specification).map((item, idx) => {
                  return (
                    <Grid key={idx} item xs={12} md={6}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <Typography className={categoryStyle.label}>
                            {item}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Controls.InputField
                            className={categoryStyle.formTextBar2}
                            name={item}
                            placeholder={`Enter ${item}`}
                            value={specification[item]}
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {values.warranty && Object.keys(values.warranty).length > 0 &&
                  Object.keys(values.warranty).map((item, idx) => {
                    return (
                      <Grid key={idx} item xs={12} md={6}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Typography className={categoryStyle.label}>
                              {`Free ${item}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Controls.InputField
                              className={categoryStyle.formTextBar2}
                              name={item}
                              placeholder={`Enter ${item}`}
                              value={values.warranty[item]}
                              type="text"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              {producttype !== 'Services' &&<Grid item xs={12} md={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography className={categoryStyle.label}>
                      Images
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <IconButs
                      ibtname={DEALER_LIST_DOC}
                      className={categoryStyle.imageButton}
                      onClick={() => setImageOPen(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>}
              {/* <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={4} md={7}>
                    <Typography className={categoryStyle.label}>
                      Add Dealer
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={5}>
                    <div className={viewStyle.excelData}>
                      <IconButs
                        ibtname={ICON_BUTTON_ADD_PRODUCT}
                        // ibtname={catId ? ICON_BUTTON_SUB_CATEGORY : ICON_BUTTON_CATEGORY}
                        icon={<AiOutlinePlus />}
                        className={categoryStyle.uploadInventory}
                        // onClick={handleOpen}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                     style={{border:"1px solid #D9D9D9",
                    borderRadius:"8px",
                    marginLeft:"14px",
                    overflowY:"auto"
                  }}>
                      <section>
                        <TblContainer >
                          <TblHead />
                          <TableBody>
                            {data.map((item) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">
                                    {item.dealerName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.Brand}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.location}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.status}
                                  </TableCell>

                                  <TableCell align="center">
                                    <Box
                                      sx={{
                                        display: { xs: "flex", md: "inline" },
                                        justifyContent: "center",
                                      }}
                                    >
                                      <IconButton>
                                        <AiOutlineEye className="user-icons" />
                                      </IconButton>
                                      <IconButton>
                                        <FaEdit className="user-icons" />
                                      </IconButton>
                                      <IconButton>
                                        <AiOutlineDelete className="user-icons" />
                                      </IconButton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </TblContainer>
                      </section>
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "start", md: "end" },
                      }}
                    >
                      <IconButs
                        ibtname="Add"
                        className={categoryStyle.formButtonAdd}
                        // onClick={handleClick}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "start", md: "center" },
                  }}
                >
                
                  <Link to={link}>
                    <IconButs
                      ibtname="OK"
                      className={categoryStyle.formButtonAdd}
                    />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
      <InventoryImage        
        open={imageOpen}
        setOpen={setImageOPen}
        data={productDetails && productDetails && productDetails.images}
        isView={true}
        newItem={false}
        type='multiple_image'
        title="Product Images"
      />
    </>
  );
};

export default ProductViewDetails;
